import Button from "../../layouts/Button/Button";
import Form from "../../layouts/Form/Form";
import "./Offers.css";
import React, { useEffect, useState,useRef } from "react";
import logo from "../../images/logo.png";
import { RotatingTriangles } from 'react-loader-spinner'
import { OffersData } from "./OffersData";


const Offers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  const [inputs, setInputs] = useState({});
  const [loading,setLoading]=useState(false);
  const [conType,setConType]=useState("trial")
  const inputRef = useRef();
  const initiateForm=(index)=>{
    inputRef.current.focus();
    let value="trial";
    switch (index) {
      case 0:
        value="trial";
        break;
        case 1:
          value="reading";
          break;
          case 2:
        value="session";
        break;
      default:
        break;
    }
    setConType(value)
  }
  const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }))
  }
  const currency = "INR";
  const receiptId = (Math.floor((Math.random() * 100000) + 1)).toString();
  const paymentHandler = async (e,selectedConType) => {
    setLoading(true);
    let amount;
    switch (selectedConType) {
      case "trial":
        amount=0;
        break;
        case "reading":
          amount=39900;
          break;
          case "session":
        amount=109900;
        break;
      default:
        amount=0;
        break;
    }
    e.preventDefault();
    const sendMailToAdmin = await fetch("https://astronumerals-backend.onrender.com/sendmail", {
      method: "POST",
      body: JSON.stringify(
          {sub:"New payment initiation at Astronumerals",body:`<p>RECEIPT ID:${receiptId}</p><p>USERNAME: ${inputs.username}</p><p>EMAIL: ${inputs.email}</p><p>DOB: ${inputs.dob}</p><p>MESSAGE: ${inputs.message}</p><p>CONSULTANCY TYPE: ${conType}</p><p>AMOUNT: ${Math.floor(amount / 100)}</p>`}),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Mail triggered")
    const responseMailToAdmin = await sendMailToAdmin.json();
    if(amount!==0){
      console.log("Razorpay initiated")
      const response = await fetch("https://astronumerals-backend.onrender.com/order", {
        method: "POST",
        body: JSON.stringify({
          amount,
          currency,
          receipt: receiptId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const order = await response.json();
      console.log(order);
  
      var options = {
        key: "rzp_live_uwlZ87qFcjYgo7", // Enter the Key ID generated from the Dashboard
        amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency,
        name: "Astronumerals", //your business name
        description: "Test Transaction",
        image: logo,
        order_id: order.id, //Pass the `id` obtained in the response of Step 1
        //callback_url: "https://astronumerals.com/paymentsuccess",
        handler: async function (response) {
          const body = {
            ...response,
          };
  
          const validateRes = await fetch(
            "https://astronumerals-backend.onrender.com/order/validate",
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const jsonRes = await validateRes.json();
          console.log(jsonRes)
          if(jsonRes.msg=="success"){
            const sendMailToUser = await fetch("https://astronumerals-backend.onrender.com/sendmail", {
              method: "POST",
              body: JSON.stringify(
                {sub:"Payment Successful at Astronumerals",body:`<p>RECEIPT ID:${receiptId}</p><p>ORDER ID:${jsonRes.orderId}</p><p>PAYMENT ID:${jsonRes.paymentId}</p><p>USERNAME: ${inputs.username}</p><p>EMAIL: ${inputs.email}</p><p>DOB: ${inputs.dob}</p><p>MESSAGE: ${inputs.message}</p><p>AMOUNT: ${Math.floor(amount / 100)}</p>`}),
              headers: {
                "Content-Type": "application/json",
              },
            });
            const responseMailToUser = await sendMailToUser.json();
            const sendMailToAdmin2 = await fetch("https://astronumerals-backend.onrender.com/sendmail", {
              method: "POST",
                  body: JSON.stringify(
                    {to:inputs.email,sub:"Payment Successful at Astronumerals",body:`Thank you for availing our astrology services at ASTRONUMERALS.<br/> We will contact you soon regarding the further steps. Please find your payment details below: <p>ORDER ID:${jsonRes.orderId}</p><p>PAYMENT ID:${jsonRes.paymentId}</p><p>USERNAME: ${inputs.username}</p><p>EMAIL: ${inputs.email}</p><p>DOB: ${inputs.dob}</p><p>MESSAGE: ${inputs.message}</p><p>AMOUNT: ${Math.floor(amount / 100)}</p>`}),
              headers: {
                "Content-Type": "application/json",
              },
            });
            const responseMailToAdmin2= await sendMailToAdmin2.json();
            setInputs("");
            window.location.href = "https://astronumerals.com/paymentsuccess"
          };
        },
        //redirect:true,
        prefill: {
          //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          name: inputs.username, //your customer's name
          email: inputs.email,
          // contact: inputs.contact, //Provide the customer's phone number for better conversion rates
        },
        notes: {
          name: inputs.username,
          dob:inputs.dob,
          message:inputs.message
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    }else{
      setInputs("");
            window.location.href = "https://astronumerals.com/paymentsuccess"
    }
      
    e.preventDefault();
    setLoading(false);
  };

  return (
    <>
      <div className="offerHeader">
        Unlock the Secrets of Your Life with Our Online Numerology Consultation
      </div>
      <p className="offerText1">
        Discover the hidden patterns and insights of your life through the power
        of numbers.
      </p>
      <div className="topSubSection">
                {Object.keys(OffersData[0]).map((key, index) => {
                  return (
                      <div key={key} className="topSubSection_Card">
                        <h1>{key}</h1>
                        <br/>
                        <ul>
                          {OffersData[0][key]["Pointers"].map((line, index) => (
                            <li key={line}>
                              {line}
                              <br />
                            </li>
                          ))}
                        </ul>
                        <button className="buttonPayment" onClick={()=>{initiateForm(index)}}>
          <p>{OffersData[0][key]["Button"]}</p>
        </button>
                      </div>
                    )
                })}
      </div>
      <form className="Form" onSubmit={(e)=>paymentHandler(e,e.nativeEvent.submitter.name)}>
            <div className='FormFields'>
                <div className="Column1">
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="username">NAME</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="text"
                                name="username"
                                value={inputs.username || ""}
                                placeholder="Your name"
                                onChange={handleChange}
                                required
                                ref={inputRef}
                            />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-25">
                            <label htmlFor="contact">CONTACT NUMBER</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="tel"
                                name="contact"
                                value={inputs.contact || ""}
                                placeholder="Your contact number"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="email">EMAIL</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="email"
                                name="email"
                                value={inputs.email || ""}
                                onChange={handleChange}
                                placeholder="Your email address"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="dob">DATE OF BIRTH</label>
                        </div>
                        <div className="col-75">
                            <input
                                type="date"
                                name="dob"
                                placeholder='Your D.O.B.'
                                value={inputs.dob || ""}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-25">
                            <label htmlFor="option">Consultation Type</label>
                        </div>
                        <div className="col-75 radioDiv">
                          <div>
                        <input type="radio" id="trial" checked={conType=="trial"} onChange={()=>{setConType("trial")}}name="con_type"value="trial"/><label htmlFor="trial">Trial</label>
</div><div><input type="radio" id="reading" name="con_type" value="reading"  checked={conType=="reading"}onChange={()=>{setConType("reading")}}/><label htmlFor="reading">Reading</label>
</div>
<div><input type="radio" id="session" name="con_type" value="session" checked={conType=="session"}onChange={()=>{setConType("session")}}/><label htmlFor="session">Session</label>
</div>
                            {/* <input
                                type="email"
                                name="email"
                                value={inputs.email || ""}
                                onChange={handleChange}
                                placeholder="Your email address"
                                required
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="Column2 row">
                    <div className="col-25">
                        <label htmlFor="message">MESSAGE</label>
                    </div>
                    <div className="textAreaDiv">
                        <textarea
                            cols='60' rows='8' wrap="hard"
                            name="message"
                            value={inputs.message || ""}
                            onChange={handleChange}
                            placeholder='Your message'
                            ></textarea>
                    </div>
                </div>
            </div>
            {loading ? <RotatingTriangles
      visible={true}
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="rotating-triangles-loading"
      wrapperStyle={{marginLeft:"40vw",marginTop:"5%"}}
      wrapperClass=""
      />:(<>
      <div className="btnPaymentContainer">
        <button type="submit"className="buttonPayment finalSubmit" name={conType}>
          {conType=="trial" && <p>Book Free Trial</p>}
          {conType=="reading" && <p>Book Readings at <s>₹999/-</s> ₹399/-</p>}
          {conType=="session" && <p>Book Session at <s>₹1999/-</s> ₹1099/-</p>}
        </button>
        </div>
      {/* <div className="btnPaymentContainer">
        <button type="submit"className="buttonPayment" name="109900">
          <p>Book Your 20-Minute Session Now at <s>₹1999/-</s> ₹1099/-</p>
        </button>
        </div>
        <div className="btnPaymentContainer">
        <button type="submit"className="buttonPayment" name="39900">
        <p>Book Full Readings and Predictions at <s>₹999/-</s> ₹399/-</p>
        </button>
        </div> */}
        </>)}
            
        </form>
      
      <div className="offerTextImgCon">
        <div className="offerText2">
          <h1>Discover a Range of Services to Enhance Your Life</h1>
          <p>
            Unlock deeper insights and guidance with our array of personalized
            services. Whether you're seeking clarity in your career, love life,
            health, or personal growth, we offer comprehensive solutions
            tailored to your needs.
          </p>
          <Button url="/services" title="Our Services" cName="ButtonDiv" />
        </div>
        <div className="circle4">
          <img
            src={require(`../../images/offerImages/offer_1.png`)}
            alt="offer"
          ></img>
        </div>
      </div>
      <div className="offerText2">
        <h1>What You Will Receive</h1>
      </div>
      <div className="allSubSectionOffer">
        <div className="allSubSection_CardOffer">
          <div>
          <h1>01</h1>
          <img
            src={require(`../../images/offerImages/offer_2.png`)}
            alt="offerImage"
          ></img>

          
          </div>
          <p>Comprehensive</p>
          <p>Analysis</p>
        </div>
        <div className="allSubSection_CardOffer">
          <div>
          <h1>02</h1>
          <img
            src={require(`../../images/offerImages/offer_3.png`)}
            alt="offerImage"
          ></img>
         
          </div>
          <p>Personalized</p><p> Insights</p>
        </div>
        <div className="allSubSection_CardOffer">
          <div>
          <h1>03</h1>
          <img
            src={require(`../../images/offerImages/offer_4.png`)}
            alt="offerImage"
          ></img>
          
          </div>
          <p>Customized</p><p>Remedies</p>
        </div>
      </div>
      <div className="offerText2">
        <h1>How It Works?</h1>
      </div>
      <div className="flowContainer">
        <div className="flowCircle">
        <h2 className="flowHeader">Book your
        Session</h2>
        </div>
        <div className="flowImages">
          <img className="flowLine1"src={require(`../../images/offerImages/arrow_line.png`)}></img>
          <img className="flowArrow"src={require(`../../images/offerImages/arrow.png`)}></img>
          <img className="flowLine2"src={require(`../../images/offerImages/arrow_line.png`)}></img>
        </div>
        <div className="flowCircle">
        <h2 className="flowHeader">Provide
        Details</h2>
        </div>
        <div className="flowImages">
          <img className="flowLine1"src={require(`../../images/offerImages/arrow_line.png`)}></img>
          <img className="flowArrow"src={require(`../../images/offerImages/arrow.png`)}></img>
          <img className="flowLine2"src={require(`../../images/offerImages/arrow_line.png`)}></img>
        </div>
        <div className="flowCircle">
        <h2 className="flowHeader">Consultation</h2>
        </div>
        <div className="flowImages">
          <img className="flowLine1"src={require(`../../images/offerImages/arrow_line.png`)}></img>
          <img className="flowArrow"src={require(`../../images/offerImages/arrow.png`)}></img>
          <img className="flowLine2"src={require(`../../images/offerImages/arrow_line.png`)}></img>
        </div>
        <div className="flowCircle">
        <h2 className="flowHeader">Follow Up</h2>
        </div>
      </div>
      <div className="formHeader">
        Book Your Consultation Today and Unlock the Power of Numerology!
      </div>
      <Form />
      <div className="getAnswersOffers">
        <h2>Get Answers to Your Questions on:</h2>
        <ul>
          <li>Name and Date of Birth Analysis</li>
          <li>Numerology Name Analysis</li>
          <li>Lo Shu Grid Creation</li>
          <li>Missing Number Remedies</li>
          <li>Name Correction</li>
          <li>Main Planet Number</li>
        </ul>
        <ul>
          <li>Life Path Number</li>
          <li>Compound Destiny Number</li>
          <li>Destiny/Name Number</li>
          <li>Personalized Remedies</li>
          <li>Signature Correction</li>
          <li>Lucky Numbers and Colors</li>
        </ul>
      </div>

      <h1 className="queryHeader">Got Queries? We’ve got your answers.</h1>
      <div className="queryContainer queryOffer">
        <div className="queryCard">
          <h5>What is the technique used for the analysis?</h5>
          <p>
            We use a combination of astrological knowledge and numerological
            aspects based on birth dates.
          </p>
        </div>
        <div className="queryCard">
          <h5>Is the process confidential?</h5>
          <p>
            Absolutely! Your details are completely safe and the analysis is
            done maintaining strict confidentiality.
          </p>
        </div>
      </div>
      <div className="queryContainer queryOffer">
        <div className="queryCard">
          <h5>How to book a session?</h5>
          <p>
            Simply click on the “Get In Touch” button on our page. You’ll be
            directed to fill up a form. Our team will get back to you post
            submission.
          </p>
        </div>

        {/* <div className="queryCard">
     <h5>How to book a session?</h5>
     <p>
     Simply click on the “Get In Touch” button on our page. You’ll be directed to fill up a form. Our team will get back to you post submission.
     </p>
   </div> */}
      </div>
    </>
  );
};

export default Offers;
