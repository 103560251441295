import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails.jsx";
import GetInTouch from "./pages/GetInTouch/GetInTouch";
import Policy from "./pages/Policy/Policy";
import TnC from "./pages/TnC/TnC";
import Offers from "./pages/Offers/Offers";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Refund from "./pages/Refund/Refund.jsx";
import Shipping from "./pages/Shipping/Shipping.jsx";
import ReactGA from "react-ga";
import { useEffect } from "react";

const TRACKING_ID = "G-FXMN7KMC34";
ReactGA.initialize(TRACKING_ID);

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}
function App() {
  useEffect(() => {
    //fetch("http://localhost:5000")
    fetch("https://astronumerals-backend.onrender.com")
      .then(async res => {
        var data = await res.json();
        console.log(data);
      })
      .catch(error => {
        console.log("There was an error!", error);
      });
  }, []);

  usePageViews();
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:id" element={<ServicesDetails />} />
        <Route path="/getintouch" element={<GetInTouch />} />
        <Route path="/privacypolicy" element={<Policy />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/paymentSuccess" element={<PaymentSuccess />} />
        <Route path="/offers" element={<Offers />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
